import React from "react";
import logo from "./logo.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <ul>
          <p>
            <i>Escape the Rat Race: Ride the North Star ROI Rocket to Success!</i>
          </p>
        </ul>
      </header>
      <footer className="App-footer">
        <p>Copyright © 2023</p>
      </footer>
    </div>
  );
}

export default App;
